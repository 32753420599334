import React from 'react';

function AboutUs() {
    return (
        <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#152631',
            padding: 18,
            boxSizing: 'border-box',
            position: 'relative',
            top: 200,
            paddingBottom: 160,
        }}>
            <h3>About Golands</h3>
            <p style={{
                fontWeight: 400,
                margin: '6px 0',
                maxWidth: 900,
            }}>
                GOLANDS is the first of its kind smart application that serves as an active Market Place for online land trade, bringing the sale and purchase of lands to your fingertips.
                <br />
                <br />
                With millions of listed lands in the market, this platform provides a unique and seamless experience to users by capturing opportunities that present buyers with an extensive range of options and enable sellers to have the most optimal reach to potential buyers.
                <br />
                <br />
                GOLANDS protects the exclusivity of posted lands and has a distinctive feature of being directly linked to Google Maps, making it effortless for users to know the exact location of a land without having to drive a mile.
                <br />
                <br />
                Whether you have an interest to buy, sell or invest in a land, GOLANDS is the prominent solution that caters to your comfort, making the sale and purchase of lands only a click away.
                <br />
                <br />
                <br />
                <h1 style={{ color: '#80bc00' }}>Need to get in touch?</h1>
                <br />
                <svg
                    width={20}
                    height={20}
                    viewBox="0 0 15 15"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <g id="WebApp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Single-Land" transform="translate(-1120.000000, -560.000000)" fill="#80bc00">
                            <g id="Group-3" transform="translate(1106.000000, 551.000000)">
                                <g id="Group-7">
                                    <g id="Group-6" transform="translate(14.000000, 6.000000)">
                                        <path
                                            d="M11.1916667,12.9416667 C10.9,12.85 10.575,12.9166667 10.3416667,13.1416667 L8.50833333,14.975 C6.15,13.775 4.21666667,11.85 3.01666667,9.49166667 L4.85,7.65 C5.08333333,7.425 5.15,7.1 5.05833333,6.80833333 C4.75,5.875 4.58333333,4.875 4.58333333,3.83333333 C4.58333333,3.375 4.20833333,3 3.75,3 L0.833333333,3 C0.375,3 0,3.375 0,3.83333333 C0,11.6583333 6.34166667,18 14.1666667,18 C14.625,18 15,17.625 15,17.1666667 L15,14.25 C15,13.7916667 14.625,13.4166667 14.1666667,13.4166667 C13.125,13.4166667 12.125,13.25 11.1916667,12.9416667 Z"
                                            id="Path"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <a href='tel:+96879969060'><span style={{ padding: 10 }}>+96879969060</span></a>
                <br />
                <svg
                    width={24}
                    height={20}
                    viewBox="0 0 16 12"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <g id="WebApp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Single-Land" transform="translate(-1214.000000, -562.000000)">
                            <g id="Group-3" transform="translate(1106.000000, 551.000000)">
                                <g id="Group-7" transform="translate(99.000000, 0.000000)">
                                    <g id="Group-8" transform="translate(8.000000, 5.000000)">
                                        <g id="ic_email" transform="translate(0.000000, 3.000000)">
                                            <g id="Icon-24px">
                                                <path
                                                    d="M15,3 L3,3 C2.175,3 1.5075,3.675 1.5075,4.5 L1.5,13.5 C1.5,14.325 2.175,15 3,15 L15,15 C15.825,15 16.5,14.325 16.5,13.5 L16.5,4.5 C16.5,3.675 15.825,3 15,3 L15,3 Z M15,6 L9,9.75 L3,6 L3,4.5 L9,8.25 L15,4.5 L15,6 L15,6 Z"
                                                    id="Shape"
                                                    fill="#80bc00"
                                                ></path>
                                                <polygon id="Shape" points="0 0 18 0 18 18 0 18"></polygon>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <a href='mailto:support@golands.com'><span style={{ padding: 10 }}>support@golands.com</span></a>
            </p>
        </div>
    )
}

export default AboutUs