import './App.css';
import AboutUs from './components/AboutUs';
import HomePage from './components/HomePage';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Terms from './components/Terms';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms-conditions" element={<Terms />} />
      </Routes>
      <Helmet>
        <script src={process.env.PUBLIC_URL + '/assets/js/vendor/modernizr-3.6.0.min.js'}></script>
        <script src={process.env.PUBLIC_URL + '/assets/js/vendor/jquery-1.12.4.min.js'}></script>
        <script src={process.env.PUBLIC_URL + '/assets/js/bootstrap.min.js'}></script>
        <script src={process.env.PUBLIC_URL + '/assets/js/popper.min.js'}></script>
        <script src={process.env.PUBLIC_URL + '/assets/js/wow.js'}></script>
        <script src={process.env.PUBLIC_URL + '/assets/js/waypoints.min.js'}></script>
        <script src={process.env.PUBLIC_URL + '/assets/js/TweenMax.min.js'}></script>
        <script src={process.env.PUBLIC_URL + '/assets/js/slick.min.js'}></script>
        <script src={process.env.PUBLIC_URL + '/assets/js/jquery.magnific-popup.min.js'}></script>
        <script src={process.env.PUBLIC_URL + '/assets/js/main.js'}></script>
      </Helmet>
    </Router>
  );
}

export default App;
