import React from 'react'

function Terms() {
    return (
        <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#152631',
            padding: 18,
            boxSizing: 'border-box',
            position: 'relative',
            top: 200,
            paddingBottom: 160,
        }}>
            <h3>
                Terms and conditions
            </h3>
            <br />
            <div>
                <h5>Thank you for using GoLands' app.</h5>
                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>This document contains the terms & conditions which governing the legal relationship between GoLands' as
                    a service provider & customers, and as users of the application, you’re required to approve them & any
                    modifications to them by deletion or addition.</p>
                <br />
                <h5>In this document:

                </h5>
                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>
                    (Services) means all services provided by GoLands' app related to lands of all kinds, that are updated
                    from time to time with addition or deletion...
                </p>
                <br />
                <h5>1- Accepting terms & conditions
                </h5>
                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>1.1 You must accept the terms and conditions in order to be able to use the services. By clicking on the
                    "Accept" button.
                </p>
                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>
                    1.2 You are bound by the terms and conditions of the document from the moment you start using GoLands
                    services.
                </p>
                <br />
                <h5>2- Terms & conditions’ changes
                </h5>
                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>2.1 GoLands' has the right to modify or change the terms & conditions from time to time. You will be
                    notified in advance of any changes to the terms & conditions.
                </p>
                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>
                    2.2 You will be required to accept the amended terms & conditions after the notice period has expired,
                    by clicking on the "Accept" button, as you can’t use our services unless you agree to the modification
                    to the terms & conditions.
                </p>
                <br />
                <h5>
                    3- Terms and conditions’ termination: termination consequences
                </h5>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>3.1 This document is valid against the user unless it’s terminated by GoLands.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>3.2 The user can terminate his commitment to this document once his user account is closed.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>3.4 GoLands' has the right to terminate this document against the customer and close his account in the
                    app
                    at any time without considerate to notice period if:</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>3.4.1 The terms & conditions contained in this document have been violated.
                </p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}> 3.4.2 He clearly demonstrated, either by his actions or by declarations, his unwillingness to comply
                    with
                    the terms & conditions.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>3.4.3 GoLands' are required to terminate services’ providing under an applicable law, whether due to
                    changes
                    in the applicable law or any other reason.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>3.5 The termination of this document doesn’t violate any rights or obligations accumulated or incurred by
                    the customer or GoLands' during this period of validity.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>3.6 In case of death the account is closed via GoLands.</p>
                <br />
                <h5>4- Terms and conditions</h5>

                <h5>In order to use GoLands' services, you must comply with the following terms and conditions:</h5>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>4.1 GoLands' registered user is obliged to be over 18 years of age and may be required to document this if</p>
                needed.

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>4.2 Don’t misuse GoLands' services</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>4.3 Use of our services as permitted by law.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>4.4 Not to publish any incorrect, forged or fake data or information,</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>4.5 Not to publish any advertisement or data on behalf of other people directly or indirectly, until they</p>
                have been formally & legally authorized by the landowners.

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>4.6 Providing GoLands' management with government documents, authorizations, approvals & licenses on
                    request.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>4.7 Ensuring that all the information that he adds in his GoLands account is entirely correct, and the user
                    is fully responsible for any wrong information he adds.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>4.8 Don’t use the GoLands' app in a way that distracts you & prevents you complying with traffic & safety
                    laws.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>4.9 All Data, regardless of its nature, or the medium used, or those related to the users or advertisers and</p>
                all statistical data are owned exclusively by GoLands' , and shall not be used without an express consent
                from GoLands'
                <br />
                <br />
                <h5>5- Registration data & user account</h5>

                <h5>Registering in GoLands' app requires the following information be provided accurately & clearly:</h5>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>Email</p>
                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>Phone number.</p>

<br />
                <h5>6- Passwords & account security</h5>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}> 6.1 You must open a user account & choose a username & password, so you can use the services.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>6.2 The user registered in GoLands' pledges to be the only one who uses the account & is responsible for
                    everything that is done through his account, and it isn’t currently allowed for more than one person to use
                    one independent account.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>6.3 You must maintain your data in a secure manner to prevent unauthorized access to it. This is done by
                    avoiding setting user identification numbers or clear passwords, where you must change your password
                    regularly and not disclose it to a third party.</p>

                    <br />
                <h5>7- Disclaimer: GoLands' is not legally responsible for:</h5>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>7.1 The validity of the data & information of the real owner of the advertised land.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}> 7.2 The validity of the data & information on the advertised land in particular (space and price) as the
                    advertiser user is solely responsible for the validity of all that information and data and bears all legal
                    consequences in this regard.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>7.3 The validity of the data and information on the location of the land as GoLands' uses Google Maps
                    service to determine the land location and therefore the error in determining the exact location of the land
                    is possible.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>7.4 For any commercial transactions such as sale, leasing and purchase, and have no control or authority
                    over such operations as GoLands' doesn’t control & isn’t responsible for any content, advertisements,
                    information, publications, data, or images published in GoLands.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>7.5 Any transfers outside GoLands approved channels.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>7.6 Any scams carried out through any content, advertisements, information, publications, or data published
                    in the GoLands app', as the user is responsible for verifying their authenticity before any transaction is
                    completed. GoLands' management can help him with that.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>7.7 For any loss of profits or revenues, financial losses, indirect or dependency damages.</p>

<br />
                <h5>8. Use our services</h5>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>In the case that our services are used on behalf of any institution, this institution accepts these terms
                    and indemnifies GoLands' and its subsidiaries, agents and employees in the case of any claim or action
                    caused by the violation of one of the conditions. Compensation shall be comprehensive for any losses or
                    damages, litigation costs, and attorneys' fees.</p>

                    <br />
                <h5>9. Dealing with content</h5>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>9.1 GoLands' has the right to delete or hide offers or projects that violate the terms of the site or those
                    that cause harm to the site or its users and without return to the user.</p>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}> 9.2 GoLands' has the right to ban accounts that violate the terms of the site or those that cause damage to
                    the site or its users permanently & without returning to the user or alerting him.</p>

                    <br />
                <h5>10.Service fee</h5>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>GoLands' has the right to impose fees for the use of GoLands' Application, thus, users adhere to pay all
                    fees imposed.</p>

                    <br />
                <h5>11. intellectual Property Rights</h5>

                <p style={{
                    fontWeight: 400,
                    margin: '6px 0',
                    maxWidth: 900,
                }}>The program and all services herein contain, and brands associated with are owned exclusively by GoLands,
                    For this purpose, GoLands has the rights to protect all these mentioned intellectual property rights from
                    any infringement.</p>
            </div>
        </div>
    )
}

export default Terms;