import React from 'react'

function HomePage() {

    return (
        <>
            <section className="appie-hero-area appie-hero-6-area appie-hero-7-area">
                <div className="container">
                    <div className="row align-items-center">
                        <img className='img-responsive' src='assets/images/logo.png' alt='' />
                        <div className="col-lg-7">
                            <div className="appie-hero-content appie-hero-content-6 appie-hero-content-7">
                                <h1 className="appie-title">Smart way to Buy and Sell your <strong style={{ color: '#94E777' }}>LAND</strong> </h1>
                                <p>Innovative app to buy and sell of lands through smart solutions and market strategies.</p>
                                <ul>
                                    <li><a href="https://apps.apple.com/om/app/golands/id1560561613" target={'_blank'} rel="noreferrer"><i className="fab fa-apple"></i> <span>Available on the <span>App Store</span></span></a></li>
                                    <li><a className="item-2" href="https://play.google.com/store/apps/details?id=com.phazero.golands&hl=en&gl=US" target={'_blank'} rel="noreferrer"><i className="fab fa-google-play"></i> <span>Available on the <span>Google Play</span></span></a></li>
                                </ul>
                                <div className="info">

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="appie-hero-thumb-6">
                                <div className="thumb wow animated fadeInUp text-center" data-wow-duration="1000ms" data-wow-delay="600ms">
                                    <img src="assets/images/hero-thumb-8.png" alt="" />
                                    <div className="back-image">
                                        <img src="assets/images/hero-shape-2.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- ====== APPIE HERO PART ENDS ====== --> */}

            {/* <section className="appie-features-area pt-100" id="features">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className="appie-features-tabs-btn">
                                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><i className="fas fa-cog"></i> Settings</a>
                                    <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false"><i className="fas fa-exclamation-triangle"></i> Report</a>
                                    <a className="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false"><i className="fas fa-bell"></i> Saeety Notices</a>
                                    <a className="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false"><i className="fas fa-lock"></i> App Lock</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="appie-features-thumb text-center wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                                <img src="assets/images/features-thumb-1.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="appie-features-content wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                                <span>Custom Reacyions</span>
                                                <h3 className="title">Let the <br /> Conversation flow</h3>
                                                <p>Car boot absolutely bladdered posh burke the wireless mush some dodg.</p>
                                                <a className="main-btn" href="#">Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                                <img src="assets/images/features-thumb-1.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                                <span>Custom Reacyions</span>
                                                <h3 className="title">Let the <br /> Conversation flow</h3>
                                                <p>Car boot absolutely bladdered posh burke the wireless mush some dodg.</p>
                                                <a className="main-btn" href="#">Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                                <img src="assets/images/features-thumb-1.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                                <span>Custom Reacyions</span>
                                                <h3 className="title">Let the <br /> Conversation flow</h3>
                                                <p>Car boot absolutely bladdered posh burke the wireless mush some dodg.</p>
                                                <a className="main-btn" href="#">Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                                <img src="assets/images/features-thumb-1.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                                <span>Custom Reacyions</span>
                                                <h3 className="title">Let the <br /> Conversation flow</h3>
                                                <p>Car boot absolutely bladdered posh burke the wireless mush some dodg.</p>
                                                <a className="main-btn" href>Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="features-shape-1">
                    <img src="assets/images/shape/shape-6.png" alt="" />
                </div>
                <div className="features-shape-2">
                    <img src="assets/images/shape/shape-7.png" alt="" />
                </div>
                <div className="features-shape-3">
                    <img src="assets/images/shape/shape-8.png" alt="" />
                </div>
            </section> */}

            {/* <section className="appie-counter-area pt-75 pb-190">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Services we provide</h3>
                                <p className='mt-2'>We believe in delivering excellence in everything we do and are dedicated to helping our clients achieve their goals.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src="https://golands-bucket.s3.me-south-1.amazonaws.com/icons/top_broker.png" width={150} alt="" />
                                    </div>
                                    <p className='mt-2' style={{ fontSize: 16 }}>Broker List</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30 item-2 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src="https://golands-bucket.s3.me-south-1.amazonaws.com/icons/request_a_land.png" width={150} alt="" />
                                    </div>
                                    <p className='mt-2' style={{ fontSize: 16 }}>Request Land</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30 item-3 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src="https://golands-bucket.s3.me-south-1.amazonaws.com/icons/land_locator.png" width={150} alt="" />
                                    </div>
                                    <p className='mt-2' style={{ fontSize: 16 }}>Land Locator</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30 item-4 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src="https://golands-bucket.s3.me-south-1.amazonaws.com/icons/subscription.png" width={150} alt="" />
                                    </div>
                                    <p className='mt-2'>Lands in Demand</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <section className="appie-download-area pt-150 pb-160">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="appie-download-content">
                                {/* <span>Download Our App</span> */}
                                <h3 className="title">App is available <br /> for free on app store</h3>
                                <div style={{ height: 30 }}></div>
                                {/* <p>Jolly good quaint james bond victoria sponge happy days cras arse over blatant.</p> */}
                                <ul>
                                    <li>
                                        <a href="https://apps.apple.com/om/app/golands/id1560561613" target={'_blank'} rel="noreferrer">
                                            <i className="fab fa-apple"></i>
                                            <span>Download for <span>iOS</span></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" target={'_blank'} href="https://play.google.com/store/apps/details?id=com.phazero.golands&hl=en&gl=US" rel="noreferrer">
                                            <i className="fab fa-google-play"></i>
                                            <span>Download for <span>Android</span></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="download-shape-1">
                    <img src="assets/images/shape/shape-15.png" alt="" />
                </div>
                <div className="download-shape-2">
                    <img src="assets/images/shape/shape-14.png" alt="" />
                </div>
                <div className="download-shape-3">
                    <img src="assets/images/shape/shape-13.png" alt="" />
                </div>
            </section>

            {/* <section className="appie-team-area pt-90 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Meet our Team Members</h3>
                                <p>Different layouts and styles for team sections.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-team-item mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                <div className="thumb">
                                    <img src="assets/images/team-1.jpg" alt="" />
                                    <ul>
                                        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                                <div className="content text-center">
                                    <h5 className="title">Benjamin Evalent</h5>
                                    <span>CEO-Founder</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-team-item mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                                <div className="thumb">
                                    <img src="assets/images/team-2.jpg" alt="" />
                                    <ul>
                                        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                                <div className="content text-center">
                                    <h5 className="title">Benjamin Evalent</h5>
                                    <span>CEO-Founder</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-team-item mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                                <div className="thumb">
                                    <img src="assets/images/team-3.jpg" alt="" />
                                    <ul>
                                        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                                <div className="content text-center">
                                    <h5 className="title">Benjamin Evalent</h5>
                                    <span>CEO-Founder</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-team-item mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                                <div className="thumb">
                                    <img src="assets/images/team-4.jpg" alt="" />
                                    <ul>
                                        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                                <div className="content text-center">
                                    <h5 className="title">Benjamin Evalent</h5>
                                    <span>CEO-Founder</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="team-btn text-center mt-50">
                                <a className="main-btn" href="#"> View all Members <i className="fal fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="appie-pricing-2-area pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Simple pricing for Everyone</h3>
                                <p>The full monty spiffing good time no biggie cack grub fantastic. </p>
                                <div className="appie-pricing-tab-btn">
                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Monthly</a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Yearly</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="pricing-one__single pricing-one__single_2 wow animated fadeInLeft">
                                                <div className="pricig-heading">
                                                    <h6>Fresh</h6>
                                                    <div className="price-range"><sup>$</sup> <span>04</span><p>/month</p></div>
                                                    <p>Get your 14 day free trial</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                        <li><i className="fal fa-check"></i> 60-day chat history</li>
                                                        <li><i className="fal fa-check"></i> 15 GB cloud storage</li>
                                                        <li><i className="fal fa-check"></i> 24/7 Support</li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">Choose plan</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="pricing-one__single pricing-one__single_2 active wow animated fadeInUp">
                                                <div className="pricig-heading">
                                                    <h6>Sweet</h6>
                                                    <div className="price-range"><sup>$</sup> <span>16</span><p>/month</p></div>
                                                    <p>Billed $276 per website annually.</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                        <li><i className="fal fa-check"></i> 60-day chat history</li>
                                                        <li><i className="fal fa-check"></i> 50 GB cloud storage</li>
                                                        <li><i className="fal fa-check"></i> 24/7 Support</li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">Choose plan</a>
                                                    </div>
                                                    <div className="pricing-rebon">
                                                        <span>Most Popular</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="pricing-one__single pricing-one__single_2 item-2 wow animated fadeInRight">
                                                <div className="pricig-heading">
                                                    <h6>Juicy</h6>
                                                    <div className="price-range"><sup>$</sup> <span>27</span><p>/month</p></div>
                                                    <p>Billed $276 per website annually.</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                        <li><i className="fal fa-check"></i> 60-day chat history</li>
                                                        <li><i className="fal fa-check"></i> Data security</li>
                                                        <li><i className="fal fa-check"></i> 100 GB cloud storage</li>
                                                        <li><i className="fal fa-check"></i> 24/7 Support</li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">Choose plan</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="pricing-one__single pricing-one__single_2 animated fadeInLeft">
                                                <div className="pricig-heading">
                                                    <h6>Fresh</h6>
                                                    <div className="price-range"><sup>$</sup> <span>32</span><p>/Yearly</p></div>
                                                    <p>Get your 14 day free trial</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                        <li><i className="fal fa-check"></i> 60-day chat history</li>
                                                        <li><i className="fal fa-check"></i> 15 GB cloud storage</li>
                                                        <li><i className="fal fa-check"></i> 24/7 Support</li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">Choose plan</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="pricing-one__single pricing-one__single_2 active animated fadeInUp">
                                                <div className="pricig-heading">
                                                    <h6>Sweet</h6>
                                                    <div className="price-range"><sup>$</sup> <span>116</span><p>/Yearly</p></div>
                                                    <p>Billed $276 per website annually.</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                        <li><i className="fal fa-check"></i> 60-day chat history</li>
                                                        <li><i className="fal fa-check"></i> 50 GB cloud storage</li>
                                                        <li><i className="fal fa-check"></i> 24/7 Support</li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">Choose plan</a>
                                                    </div>
                                                    <div className="pricing-rebon">
                                                        <span>Most Popular</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="pricing-one__single pricing-one__single_2 item-2 animated fadeInRight">
                                                <div className="pricig-heading">
                                                    <h6>Juicy</h6>
                                                    <div className="price-range"><sup>$</sup> <span>227</span><p>/Yearly</p></div>
                                                    <p>Billed $276 per website annually.</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                        <li><i className="fal fa-check"></i> 60-day chat history</li>
                                                        <li><i className="fal fa-check"></i> Data security</li>
                                                        <li><i className="fal fa-check"></i> 100 GB cloud storage</li>
                                                        <li><i className="fal fa-check"></i> 24/7 Support</li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">Choose plan</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="appie-faq-area pb-95 pt-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Frequently asked questions</h3>
                                <p>Different layouts and styles for team sections.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
                                <div className="accrodion-grp wow fadeIn" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                                    <div className="accrodion active ">
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Where do I usually find FAQs in a page?</h4>
                                            </div>
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <p>Naff Oxford vagabond in my flat chinwag blatant grub tomfoolery that I bits and bobs up the duff cras boot bevvy no biggie.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accrodion  ">
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Where do I usually find FAQs in a page?</h4>
                                            </div>
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <p>Naff Oxford vagabond in my flat chinwag blatant grub tomfoolery that I bits and bobs up the duff cras boot bevvy no biggie.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
                                <div className="accrodion-grp wow fadeIn" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                                    <div className="accrodion ">
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Where do I usually find FAQs in a page?</h4>
                                            </div>
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <p>Naff Oxford vagabond in my flat chinwag blatant grub tomfoolery that I bits and bobs up the duff cras boot bevvy no biggie.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accrodion  ">
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Where do I usually find FAQs in a page?</h4>
                                            </div>
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <p>Naff Oxford vagabond in my flat chinwag blatant grub tomfoolery that I bits and bobs up the duff cras boot bevvy no biggie.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="faq-text text-center pt-40">
                                <p>Can't find an answer? <a href="mailto:demo@gmail.com">Email us</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="appie-blog-area pt-90 pb-95">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Latest blog posts</h3>
                                <p>Different layouts and styles for team sections.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="appie-blog-item mt-30 wow animated fadeInUp" data-wow-duration="3000ms" data-wow-delay="200ms">
                                <div className="thumb">
                                    <img src="assets/images/blog-1.jpg" alt="" />
                                </div>
                                <div className="content">
                                    <div className="blog-meta">
                                        <ul>
                                            <li>March 10, 2022</li>
                                            <li><a href="#">Saas & App</a></li>
                                        </ul>
                                    </div>
                                    <h3 className="title"><a href="#">Introducing New Apps Design for our iOS App</a></h3>
                                    <a href="#">Learn More <i className="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="appie-blog-item mt-30 wow animated fadeInUp" data-wow-duration="3000ms" data-wow-delay="400ms">
                                <div className="thumb">
                                    <img src="assets/images/blog-2.jpg" alt="" />
                                </div>
                                <div className="content">
                                    <div className="blog-meta">
                                        <ul>
                                            <li>March 10, 2022</li>
                                            <li><a href="#">Saas & App</a></li>
                                        </ul>
                                    </div>
                                    <h3 className="title"><a href="#">How to bring Dolce to your company</a></h3>
                                    <a href="#">Learn More <i className="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="appie-blog-item mt-30 wow animated fadeInUp" data-wow-duration="3000ms" data-wow-delay="600ms">
                                <div className="thumb">
                                    <img src="assets/images/blog-3.jpg" alt="" />
                                </div>
                                <div className="content">
                                    <div className="blog-meta">
                                        <ul>
                                            <li>March 10, 2022</li>
                                            <li><a href="#">Saas & App</a></li>
                                        </ul>
                                    </div>
                                    <h3 className="title"><a href="#">17 Effective Ways to Monetize Mobile Apps</a></h3>
                                    <a href="#">Learn More <i className="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="appie-project-area pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-project-box wow animated slideInUp" data-wow-duration="1000ms" data-wow-delay="0ms">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="appie-project-content">
                                            <h3 className="title">Start your project with appie.</h3>
                                            <p>We will email you only about this product.</p>
                                            <form action="#">
                                                <div className="input-box mt-30">
                                                    <input type="text" placeholder="Your email" />
                                                    <button>Subscribe</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="appie-project-thumb">
                                    <img src="assets/images/project-thumb.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <Footer /> */}
        </>
    )
}

export default HomePage;